import { overlayModal, modalBig, modalMini } from "../services/variables";

function modalOpen(overlayModalActive, modalActive) {
	overlayModal.classList.add(overlayModalActive);
	modalBig.classList.add(modalActive);
}

function modalClose(overlayModalActive, modalActive) {
	overlayModal.classList.remove(overlayModalActive);
	modalBig.classList.remove(modalActive);
	modalMini.classList.remove(modalActive);
}

export { modalOpen, modalClose };
