import { forms, modalBig, overlayModal, modalMini } from "./variables";

function mailer() {
	forms.forEach((form) => {
		form.addEventListener("submit", function (e) {
			e.preventDefault();

			fetch("mailer/smart.php", {
				method: "POST",
				body: new FormData(this),
			})
				.then(function (response) {
					if (response.ok) {
						form.querySelectorAll("input").forEach((input) => {
							input.value = "";
						});
						modalBig.classList.remove("modal__active");
						overlayModal.classList.add("overlay-modal__active");
						modalMini.classList.add("modal__active");

						setTimeout(function () {
							overlayModal.classList.remove("overlay__active");
							modalMini.classList.remove("modal-modal__active");
						}, 5000);

						form.reset();
					}
				})
				.catch((error) => {
					console.log("Error:", error);
				});

			return false;
		});
	});
}
export default mailer;
