import scrolling from "../services/scrolling";
import {
	menuTop,
	menuBig,
	menuBigLogp,
	menuTopLine,
	hamburger,
} from "../services/variables";

function showBigMenu(top, action, transform) {
	menuBig.classList.add(top);
	window.removeEventListener(action, scrolling);
	menuTop.classList.add(transform);
}

function hideBigMenu(top, action) {
	menuBig.classList.remove(top);
	if (window.innerWidth >= 991) {
		window.addEventListener(action, scrolling);
	}
}

function hamburgerAct(transform, hidden, cross) {
	menuTopLine.classList.toggle(transform);
	menuBigLogp.classList.toggle(hidden);
	hamburger.classList.toggle(cross);
}

export { showBigMenu, hideBigMenu, hamburgerAct };
