"use strict";

import { modalOpen, modalClose } from "./modules/modal";
import scrolling from "./services/scrolling";
import { showBigMenu, hideBigMenu, hamburgerAct } from "./modules/menuBig";
import showText from "./modules/about";
import observer from "./services/observer";
import resize from "./services/resize";
import mailer from "./services/forms";
import mouse from "./services/mouseAction";

window.addEventListener("DOMContentLoaded", () => {
	window.addEventListener("scroll", scrolling);
	mouse();
	mailer();
	observer();
	resize();
	// scrolling();

	function eventListener(action) {
		window.addEventListener(action, (e) => {
			if (e.target.classList.contains("button")) {
				modalOpen("overlay-modal__active", "modal__active");
			} else if (
				e.target.classList.contains("modal__close") ||
				e.target.classList.contains("overlay-modal")
			) {
				modalClose("overlay-modal__active", "modal__active");
			} else if (e.target.closest(".button__Category") && innerWidth >= 768) {
				showBigMenu("top", "scroll", "transform");
			} else if (e.target.closest(".menuBig__close")) {
				hideBigMenu("top", "scroll");
			} else if (e.target.closest(".hamburger")) {
				hamburgerAct(
					"transform__translateX",
					"hidden__letter",
					"hamburger__cross"
				);
			} else if (e.target.closest(".menuTop__items_link")) {
				hamburgerAct(
					"transform__translateX",
					"hidden__letter",
					"hamburger__cross"
				);
				if (e.target.closest(".button__Category")) {
					showBigMenu("top", "scroll", "transform");
				}
			} else if (e.target.closest(".about__link")) {
				showText(e);
			}
		});
	}
	eventListener("click");
	// eventListener("touchend");

	window.addEventListener("resize", resize);
});
