import { catalogItem, overlay, catalogImg, catalogHeader } from "./variables";
function mouse(
	item,
	sliderOverlay,
	sliderOverlayBlack,
	slideBorder,
	slideLetterSmall,
	sliderLetter
) {
	function mouseAction(mouseAction, action) {
		if (catalogItem && innerWidth > 575) {
			catalogItem.forEach((item, i) => {
				item.addEventListener(mouseAction, () => {
					overlay[i].classList[action]("active__overlay");
					catalogHeader[i].classList[action]("active__header");
					catalogImg[i].classList[action]("transform__scale");
				});
			});
		}
		if (item) {
			item.forEach((item, i) => {
				item.addEventListener(mouseAction, () => {
					sliderOverlay[i].classList[action]("active__overlay");
					sliderOverlayBlack[i].classList[action]("active__overlay");
					slideBorder[i].classList[action]("active__header");
					slideLetterSmall[i].classList[action]("active__letterSmall");
					sliderLetter[i].classList[action]("hidden__letter");
				});
			});
		}
	}

	mouseAction("mouseover", "add");
	mouseAction("mouseout", "remove");
}
export default mouse;
