const overlayModal = document.querySelector(".overlay-modal");
const modalBig = document.querySelector(".modal_big");
const modalMini = document.querySelector(".modal_mini");
const menuTop = document.querySelector(".menuTop");
const menuBig = document.querySelector(".menuBig");
const menuTopLine = document.querySelector(".menuTop__line");
const menuBigLogp = document.querySelector(".menuTop__bigLogo");
const hamburger = document.querySelector(".hamburger");
const text = document.querySelectorAll(".about__text");
const forms = document.querySelectorAll("form");
const overlay = document.querySelectorAll(".overlay");
const catalogItem = document.querySelectorAll(".catalog__link");
const catalogHeader = document.querySelectorAll(".catalog__header");
const catalogImg = document.querySelectorAll(".catalog__img");
const overlayBlock = document.querySelector(".photo__overlay");
const bigImg = document.querySelector(".photo__overlay_img");
let dataObj = "";

export {
	overlayModal,
	modalBig,
	modalMini,
	menuTop,
	menuBig,
	menuTopLine,
	menuBigLogp,
	hamburger,
	text,
	forms,
	overlay,
	catalogItem,
	catalogHeader,
	catalogImg,
	dataObj,
	overlayBlock,
	bigImg,
};
