import scrolling from "./scrolling";
import { menuTop } from "./variables";

function resize() {
	if (window.innerWidth <= 991) {
		console.log(1);
		window.removeEventListener("scroll", scrolling);
		menuTop.classList.add("transform");
	} else {
		window.addEventListener("scroll", scrolling);
		menuTop.classList.remove("transform");
	}
}

export default resize;
