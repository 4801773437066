import { text } from "../services/variables";

function showText(e) {
	e.preventDefault;
	text.forEach((item) => {
		item.classList.toggle("hidden");
	});
}

export default showText;
