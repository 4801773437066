import { menuTop } from "./variables";

function scrolling() {
	if (scrollY > 200) {
		menuTop.classList.add("transform");
	} else {
		menuTop.classList.remove("transform");
	}
}

export default scrolling;
